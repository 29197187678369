import { Pie } from 'vue-chartjs'
import pluginChartText from './plugins/plugin-chart-text'
import reactiveChartMixin from './mixins/reactiveChart'

const defaultOptions = {
  elements: {
    center: {
      text: '60%',
      color: '#66615c', // Default is #000000
      fontStyle: 'Arial', // Default is Arial
      fontWeight: '',
      sidePadding: 60 // Default is 20 (as a percentage)
    }
  },
  cutoutPercentage: 90,

  legend: {
    display: false
  },

  tooltips: {
    enabled: false
  },

  scales: {
    yAxes: [{

      ticks: {
        display: false
      },
      gridLines: {
        drawBorder: false,
        zeroLineColor: 'transparent',
        color: 'rgba(255,255,255,0.05)'
      }

    }],

    xAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(255,255,255,0.1)',
        zeroLineColor: 'transparent'
      },
      ticks: {
        display: false,
      }
    }]
  },
}

export default {
  name: 'doughnut-chart',
  extends: Pie,
  mixins: [reactiveChartMixin],
  props: {
    labels: {
      type: [Object, Array],
      description: 'Chart labels. This is overridden when `data` is provided'
    },
    datasets: {
      type: [Object, Array],
      description: 'Chart datasets. This is overridden when `data` is provided'
    },
    data: {
      type: [Object, Array],
      description: 'Chart.js chart data (overrides all default data)'
    },
    extraOptions: {
      type: Object,
      description: 'Chart.js options'
    },
    title: {
      type: String,
      description: 'Chart title'
    },
    color: {
      type: String,
      default: '#4acccd',
    },
    secondaryColor: {
      type: String,
      default: '#f4f3ef',
    },
    lineWidth: {
      type: Number,
      default: 0,
    },
    cutoutPercentage: {
      type: Number,
      default: 90,
    },
    autoTextSizeAdjust: {
      type: Boolean,
      default: false,
    },
    centerText: {
      type: String,
      defalut: null,
    }
  },
  methods: {
    assignChartData() {
      return {
        labels: this.labels || [],
        datasets: this.datasets ? this.datasets : [{
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: [this.color, this.secondaryColor],
          borderColor: [this.color, this.secondaryColor],
          borderWidth: [this.lineWidth, this.lineWidth],
          data: this.data || []
        }]
      }
    },
    assignChartOptions(initialConfig) {
      let extraOptions = this.extraOptions || {
        cutoutPercentage: this.cutoutPercentage,
      }
      return {
        ...initialConfig,
        ...extraOptions
      }
    },
    drawChart() {
      this.chartData = this.assignChartData({})
      this.options = this.assignChartOptions(defaultOptions)

      this.options.elements.center.text =
        this.centerText || (this.data ? `${this.data[0]}%` : null)
      this.options.elements.center.color = this.color

      if (this.autoTextSizeAdjust) {
        // 進捗画面の表示をきれいにする用.
        const text = this.options.elements.center.text || ''

        // xx% だったら太くする
        if (text.endsWith('%')) {
          this.options.elements.center.fontWeight = 'bold'
        }

        // 文字自体の大きさを指定することはできなそうなので、周りの幅を指定する
        // ことで間接的に大きさをコントロールしておく.
        const len = text.length
        let pd = 20
        if (text.match(/^\d%$/)) {
          // ちっちゃくなっちゃうのでバランス取る
          pd = 54
        } else if (len <= 2) {
          pd = 70
        } else if (len <= 3) {
          pd = 30
        } else {
          pd = 20
        }
        this.options.elements.center.sidePadding = pd
      }
      if (this.$data._chart) {
        this.$data._chart.destroy()
      }
      this.renderChart(this.chartData, this.options)
    },
    refreshChart() {
      this.drawChart()
    },
  },
  mounted() {
    this.addPlugin(pluginChartText)
    this.drawChart()
  },
  watch: {
    centerText: function() {
      this.refreshChart()
    }
  }
}
