export function enumerateDevices(): Promise<MediaDeviceInfo[]> {
  if (navigator.mediaDevices &&
    typeof navigator.mediaDevices.enumerateDevices === 'function'
  ) {
    return navigator.mediaDevices.enumerateDevices()
  }
  return Promise.reject(new Error('enumerateDevices is not defined'))
}

export function getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream> {
  if (navigator.mediaDevices &&
    typeof navigator.mediaDevices.getUserMedia === 'function'
  ) {
    return navigator.mediaDevices.getUserMedia(constraints)
  }
  return Promise.reject(new Error('getUserMedia is not defined'))
}
