




import { defineComponent, onMounted, SetupContext } from '@vue/composition-api'
import { ensureSuperAdmin } from 'src/hooks/appRoleHook'

export default defineComponent({
  setup(_props, context: SetupContext) {
    onMounted(async() => {
      await ensureSuperAdmin(context)
    })
  },
})
