import jsQR, { QRCode } from 'jsqr'
import Bugsnag from '@bugsnag/js'
import { getUserMedia, enumerateDevices } from 'src/util/media_devices'
import { requestAnimFrame } from 'src/util/request_animation_frame'
// eslint-disable-next-line import/no-webpack-loader-syntax
import QRDecodeWorker from 'worker-loader?name=[name].[hash].worker.js!src/workers/qrdecode.worker'

interface Point {
  x: number
  y: number
}

export interface VideoDeviceInfo {
  id: string
  label: string
}

export interface QRCodeResult {
  code: QRCode['data']
  opts: {
    location: QRCode['location']
    dataCanvas: HTMLCanvasElement
    sizeAdjustedCanvas: HTMLCanvasElement
  }
}

export type onDetect = (result: QRCodeResult) => void

export interface PerformanceInfo {
  avgVideoSpeed: number
  avgDetectSpeed: number
  avgVideoFps: number
  avgDetectFps: number
  detectInterval: number
}
export type onRecentFpsUpdate = (performanceInfo: PerformanceInfo) => void

export interface InitOptions {
  state: {
    inputStream: {
      constraints: MediaTrackConstraints
      target: Element
    }
    videoFrequency?: number
    detectFrequency?: number
    idleDetectFrequency?: number
    locate: boolean
  }
  onDetect: onDetect
  onRecentFpsUpdate?: onRecentFpsUpdate
  useWorker?: boolean
  useMotionDetect?: boolean
  useAutoReload?: boolean
  detectThres?: number
  sameResultWaitMillisec?: number
  notSameResultWaitMillisec?: number
}

export default class QrcodeManager {
  videoConstraints: any
  // 映像更新の頻度
  videoFrequency: number
  // QRコード検出の頻度
  detectFrequency: number
  origDetectFrequency: number
  // idleモード時のQRコード検出の頻度
  idleDetectFrequency: number

  video: HTMLVideoElement | null
  canvas: HTMLCanvasElement | null
  canvasCtx: CanvasRenderingContext2D | null
  sizeAdjustedCanvas: HTMLCanvasElement | null
  sizeAdjustedCanvasCtx?: CanvasRenderingContext2D | null
  desiredDetectionWindowWidth: number
  frameResizeFactor: number

  mediaStream: MediaStream | null
  lastImageData: ImageData | null
  isLastImageDataValid = false
  lastDetectLocation: {
    area: QRCode['location'] | null
    ts: number | null
  }
  lastDetectLocationLife: number // このms後に消す

  useWorker: boolean
  qrcodeWorker: QRDecodeWorker | null
  isQrcodeWorkerReady: boolean
  tmpWorkerTimestamp: number = 0

  useAutoReload: boolean
  avgVideoSpeed: number
  avgDetectSpeed: number
  recentVideoSpeeds: number[]
  recentDetectSpeeds: number[]
  speedLastNotificationTs: Date
  speedNotificationThres: number

  useMotionDetect = false
  mdCanvas: HTMLCanvasElement | null = null
  mdCanvasCtx: CanvasRenderingContext2D | null = null
  mdSampleFactor: number = 10
  mdDbgScaleFactor: number = 3
  mdTmpCounter: number = 0
  mdSkipFrames: number = 0
  lastMdImageData: number[] = []
  // 輝度っぽいものの差がこれ以上ならそのピクセルに動きありとみなす
  mdColorDiffThres: number = 50
  // 全体のピクセルの内動きありピクセルの比率がこれ以上なら動きがあったとみなす
  mdDiffPixelRatioThres: number = 0.1
  // activeになる前にこの時間以上idleだったら、場合に応じてreloadする
  idleTooLongMillisec: number = 45 * 60 * 1000
  mdStatus?: {
    current: string
    consecutiveNoMoveCount: number
    idleCountThres: number
    tmpIdleStartTs: Date
    lastIdleTimeMillisec: number
  }

  isTickStopped: boolean
  locate: boolean

  lastCodeResultTs: Date
  lastCodeResult: Partial<QRCode>
  tmpCodeResult: QRCode & { consecutiveCount: number } | null

  onDetect: onDetect
  onRecentFpsUpdate: onRecentFpsUpdate
  detectThres: number
  sameResultWaitMillisec: number
  notSameResultWaitMillisec: number

  isReaderDisabled = false
  ready: Promise<void>

  constructor(opts: InitOptions) {
    this.videoConstraints = opts.state.inputStream.constraints
    this.videoFrequency = opts.state.videoFrequency || 30
    this.detectFrequency = opts.state.detectFrequency || 50
    this.origDetectFrequency = this.detectFrequency
    this.idleDetectFrequency = opts.state.idleDetectFrequency || 500

    const elementRoot = opts.state.inputStream.target
    this.video = elementRoot.querySelector('.vid')
    this.canvas = elementRoot.querySelector('.drawingBuffer')
    this.canvasCtx = this.canvas!.getContext('2d')!
    this.sizeAdjustedCanvas = elementRoot.querySelector('.sizeAdjustedBuffer')
    if (this.sizeAdjustedCanvas) {
      this.sizeAdjustedCanvasCtx = this.sizeAdjustedCanvas.getContext('2d')!
    }
    this.desiredDetectionWindowWidth = 250
    this.frameResizeFactor = 1

    this.mediaStream = null
    this.lastImageData = null
    this.isLastImageDataValid = false
    this.lastDetectLocation = { area: null, ts: null }
    this.lastDetectLocationLife = 60

    // TODO なぜか呼ばれないのでとりあえずfalseにしておく.
    // this.useWorker = opts.useWorker || false
    this.useWorker = false
    this.qrcodeWorker = null
    this.isQrcodeWorkerReady = false

    this.useAutoReload = opts.useAutoReload || false
    this.avgVideoSpeed = 0
    this.avgDetectSpeed = 0
    this.recentVideoSpeeds = [0]
    this.recentDetectSpeeds = [0]
    this.speedLastNotificationTs = new Date(1970, 0, 1)
    this.speedNotificationThres = 1000

    // motion detect stuff
    this.useMotionDetect = opts.useMotionDetect || false
    if (this.useMotionDetect) {
      this.mdCanvas = elementRoot.querySelector('.motionDetectBuffer')
      this.mdCanvasCtx = this.mdCanvas!.getContext('2d')!
      this.mdSkipFrames = Math.floor(500 / this.videoFrequency)
      this.mdStatus = {
        current: 'active',
        consecutiveNoMoveCount: 0,
        idleCountThres: 20,
        tmpIdleStartTs: new Date(),
        lastIdleTimeMillisec: 0,
      }
    }

    this.isTickStopped = false
    this.locate = opts.state.locate

    this.lastCodeResultTs = new Date(1970, 0, 1)
    this.lastCodeResult = {}
    this.tmpCodeResult = null

    this.onDetect = opts.onDetect
    this.onRecentFpsUpdate = opts.onRecentFpsUpdate || ((_: PerformanceInfo) => {})
    this.detectThres = opts.detectThres || 1
    this.sameResultWaitMillisec = opts.sameResultWaitMillisec || 5000
    this.notSameResultWaitMillisec = opts.notSameResultWaitMillisec || 1000

    this.isReaderDisabled = false

    this.ready = this.init_()
  }

  drawLine_(canvasCtx: CanvasRenderingContext2D, begin: Point, end: Point, color: string) {
    canvasCtx.beginPath()
    canvasCtx.moveTo(begin.x, begin.y)
    canvasCtx.lineTo(end.x, end.y)
    canvasCtx.lineWidth = 4
    canvasCtx.strokeStyle = color
    canvasCtx.stroke()
  }

  onDetection_(result: QRCode) {
    if (!result.data) { return }
    // x回連続で認識したら認識を確定させる
    // 連続で同じものを認識した回数をカウント
    if (!this.tmpCodeResult || this.tmpCodeResult.data !== result.data) {
      this.tmpCodeResult = Object.assign({ consecutiveCount: 1 }, result)
    } else {
      this.tmpCodeResult.consecutiveCount++
    }

    if (this.tmpCodeResult.consecutiveCount < this.detectThres) {
      return
    }

    const now = new Date()
    const diffMillisec = now.valueOf() - this.lastCodeResultTs.valueOf()
    // 最後の検出からsameResultWaitMillisec以上経たない限り、同一値の検出を通知しない.
    if (
      this.lastCodeResult.data === result.data &&
      diffMillisec < this.sameResultWaitMillisec
    ) { return }
    // 最後の検出からnotSameResultWaitMillisec以上経たない限り、次の検出を通知しない.
    if (
      this.lastCodeResult.data !== result.data &&
      diffMillisec < this.notSameResultWaitMillisec
    ) { return }

    // 確定
    this.tmpCodeResult = null
    this.lastCodeResult = result
    this.lastCodeResultTs = now
    this.onDetect({
      code: result.data,
      opts: {
        location: result.location,
        dataCanvas: this.canvas!,
        sizeAdjustedCanvas: this.sizeAdjustedCanvas!,
      }
    })
  }

  updateCanvasImage() {
    if (!this.video || !this.canvas || !this.canvasCtx) { return }
    const video = this.video
    if (video.readyState !== video.HAVE_ENOUGH_DATA) { return }

    const vw = video.videoWidth
    const vh = video.videoHeight
    this.canvas.width = vw
    this.canvas.height = vh
    this.canvasCtx.drawImage(video, 0, 0, vw, vh)
    if (this.sizeAdjustedCanvas && this.sizeAdjustedCanvasCtx) {
      const canvas2 = this.sizeAdjustedCanvas
      const canvasCtx2 = this.sizeAdjustedCanvasCtx
      // 常にdesiredDetectionWindowWidthのサイズに直して検出を行うことにより
      // 端末によらずベストな焦点距離が一定になるはず.
      this.frameResizeFactor = this.desiredDetectionWindowWidth / vw
      const vwResized = vw * this.frameResizeFactor
      const vhResized = vh * this.frameResizeFactor
      canvas2.width = vwResized
      canvas2.height = vhResized
      canvasCtx2.drawImage(video, 0, 0, vwResized, vhResized)
      this.lastImageData = canvasCtx2.getImageData(0, 0, vwResized, vhResized)
    } else {
      this.frameResizeFactor = 1
      this.lastImageData = this.canvasCtx.getImageData(0, 0, vw, vh)
    }
    this.isLastImageDataValid = true

    if (this.lastDetectLocation.ts) {
      if (performance.now() - this.lastDetectLocation.ts < this.lastDetectLocationLife) {
        this.drawDetectionLocation()
      } else {
        this.lastDetectLocation = { area: null, ts: null }
      }
    }

    if (this.useMotionDetect) {
      if (this.mdTmpCounter++ >= this.mdSkipFrames) {
        this.mdTmpCounter = 0
        this.updateMotionDetectStatus()
      }
      if (this.mdStatus!.current === 'active') {
        // 真ん中上部に緑の丸を描く.
        this.canvasCtx.beginPath()
        this.canvasCtx.arc(this.canvas.width / 2, 15, 8, 0, Math.PI * 2, false)
        this.canvasCtx.strokeStyle = 'black'
        this.canvasCtx.fillStyle = `rgb(127, 255, 0)`
        this.canvasCtx.stroke()
        this.canvasCtx.fill()
      }
    }
  }

  updateMotionDetectStatus() {
    if (!this.useMotionDetect || !this.mdCanvas || !this.mdCanvasCtx || !this.mdStatus) { return }
    if (!this.lastImageData || !this.isLastImageDataValid) { return }
    const imageData = this.lastImageData
    const imageW = imageData.width
    const imageH = imageData.height
    const mdRawWidth = Math.floor(imageW / this.mdSampleFactor)
    const mdRawheight = Math.floor(imageH / this.mdSampleFactor)
    this.mdCanvas.width = mdRawWidth * this.mdDbgScaleFactor
    this.mdCanvas.height = mdRawheight * this.mdDbgScaleFactor

    let calcDiff = true
    if (this.lastMdImageData.length !== mdRawWidth * mdRawheight) {
      calcDiff = false
      this.lastMdImageData = new Array(mdRawWidth * mdRawheight).fill(0)
    }
    if (this.lastMdImageData.length === 0) { return }

    let numMotionPixels = 0
    // let dbgValDiffBin10 = 0
    // let dbgValDiffBin20 = 0
    // let dbgValDiffBin30 = 0
    // let dbgValDiffBin40 = 0
    // let dbgValDiffBin50 = 0
    // let dbgValDiffBin50up = 0
    for (let y = 0; y < mdRawheight; y++) {
      for (let x = 0; x < mdRawWidth; x++) {
        const srcPos = (y * imageW * this.mdSampleFactor + x * this.mdSampleFactor) * 4
        const dstPos = y * mdRawWidth + x
        const r = imageData.data[srcPos]
        const g = imageData.data[srcPos + 1]
        const b = imageData.data[srcPos + 2]
        const colorAggrVal = r / 3 + g / 3 + b / 3
        this.mdCanvasCtx.fillStyle = 'rgb(0, 0, 255)'
        if (calcDiff) {
          const colorValDiff = Math.abs(this.lastMdImageData[dstPos] - colorAggrVal)
          if (colorValDiff > this.mdColorDiffThres) {
            this.mdCanvasCtx.fillStyle = `rgb(${r}, ${g}, ${b})`
            ++numMotionPixels
          }

          // if (colorValDiff <= 10) {
          //   ++dbgValDiffBin10
          // } else if (colorValDiff <= 20) {
          //   ++dbgValDiffBin20
          // } else if (colorValDiff <= 30) {
          //   ++dbgValDiffBin30
          // } else if (colorValDiff <= 40) {
          //   ++dbgValDiffBin40
          // } else if (colorValDiff <= 50) {
          //   ++dbgValDiffBin50
          // } else {
          //   ++dbgValDiffBin50up
          // }
        }
        this.lastMdImageData[dstPos] = colorAggrVal

        this.mdCanvasCtx.fillRect(
          x * this.mdDbgScaleFactor,
          y * this.mdDbgScaleFactor,
          this.mdDbgScaleFactor,
          this.mdDbgScaleFactor)
      }
    }
    // console.log(`motion detect: numMotionPixels:${numMotionPixels}, ratio:${(numMotionPixels / this.lastMdImageData.length).toFixed(2)}`)
    // console.log(`all=${this.lastMdImageData.length}, x<=10:${dbgValDiffBin10}, x<=20:${dbgValDiffBin20}, x<=30:${dbgValDiffBin30}, x<=40:${dbgValDiffBin40}, x<=50:${dbgValDiffBin50}, x>50:${dbgValDiffBin50up}`)
    if (numMotionPixels / this.lastMdImageData.length > this.mdDiffPixelRatioThres) {
      // 動きあり
      const currentState = this.mdStatus.current
      this.mdStatus.current = 'active'
      this.mdStatus.consecutiveNoMoveCount = 0
      this.detectFrequency = this.origDetectFrequency
      if (currentState !== this.mdStatus.current) {
        // console.log(`motion detect status changed to active. speed=${this.detectFrequency}`)
        this.mdStatus.lastIdleTimeMillisec = new Date().valueOf() - this.mdStatus.tmpIdleStartTs.valueOf()
      }
    } else {
      // 動きなし
      const currentState = this.mdStatus.current
      ++this.mdStatus.consecutiveNoMoveCount
      if (this.mdStatus.consecutiveNoMoveCount >= this.mdStatus.idleCountThres) {
        this.mdStatus.current = 'idle'
        this.detectFrequency = this.idleDetectFrequency
        if (currentState !== this.mdStatus.current) {
          // console.log(`motion detect status changed to idle. speed=${this.detectFrequency}`)
          this.mdStatus.tmpIdleStartTs = new Date()
        }
      }
    }
  }

  drawDetectionLocation() {
    if (!this.lastDetectLocation.area) { return }
    const location = this.lastDetectLocation.area
    const lines = [
      [location.topLeftCorner, location.topRightCorner],
      [location.topRightCorner, location.bottomRightCorner],
      [location.bottomRightCorner, location.bottomLeftCorner],
      [location.bottomLeftCorner, location.topLeftCorner],
    ]
    lines.forEach(([p1, p2]) => {
      this.drawLine_(this.canvasCtx!, p1, p2, '#FF3B58')
    })
  }

  tryDetect() {
    if (this.isReaderDisabled) { return }
    if (!this.lastImageData || !this.isLastImageDataValid) { return }
    const imageData = this.lastImageData
    this.isLastImageDataValid = true
    if (this.useWorker) {
      if (this.isQrcodeWorkerReady) {
        this.isQrcodeWorkerReady = false
        this.tmpWorkerTimestamp = performance.now()
        try {
          this.qrcodeWorker!.postMessage({
            buffer: imageData.data.buffer,
            width: imageData.width,
            height: imageData.height,
          }, [imageData.data.buffer])
          this.isLastImageDataValid = false
        } catch (e) {
          this.isQrcodeWorkerReady = true
        }
      }
    } else {
      const tmpTimestamp = performance.now()
      let result: QRCode | null = null
      try {
        result = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
          canOverwriteImage: true,
        })
      } catch (e) { /* nothing to do */ }
      this.isLastImageDataValid = false
      const elapsed = performance.now() - tmpTimestamp
      this.updateRecentFps(null, elapsed)
      this.afterFrameDecoded(result)
    }
  }

  onFrameDecoded(evt: MessageEvent<QRCode | null>) {
    this.isQrcodeWorkerReady = true
    const result = evt.data
    const elapsed = performance.now() - this.tmpWorkerTimestamp
    this.updateRecentFps(null, elapsed)
    this.afterFrameDecoded(result)
  }

  updateRecentVideoSpeeds_(videoFrameElapsed: number) {
    const arr = this.recentVideoSpeeds
    arr.push(videoFrameElapsed)
    this.recentVideoSpeeds = arr.slice(-10)
    this.avgVideoSpeed = this.recentVideoSpeeds.reduce((acc, e) => {
      return acc + e
    }, 0) / this.recentVideoSpeeds.length
  }

  updateRecentDetectSpeeds_(detectFrameElapsed: number) {
    const arr = this.recentDetectSpeeds
    arr.push(detectFrameElapsed)
    this.recentDetectSpeeds = arr.slice(-10)
    this.avgDetectSpeed = this.recentDetectSpeeds.reduce((acc, e) => {
      return acc + e
    }, 0) / this.recentDetectSpeeds.length
  }

  updateRecentFps(videoFrameElapsed: number | null, detectFrameElapsed: number | null) {
    if (videoFrameElapsed !== null) {
      this.updateRecentVideoSpeeds_(videoFrameElapsed)
    }
    if (detectFrameElapsed !== null) {
      this.updateRecentDetectSpeeds_(detectFrameElapsed)
    }
    // データが溜まるまで少し待つ
    if (this.recentDetectSpeeds.length < 10) { return }

    if (this.onRecentFpsUpdate) {
      const ts = new Date()
      if (ts.valueOf() - this.speedLastNotificationTs.valueOf() > this.speedNotificationThres) {
        this.speedLastNotificationTs = ts
        this.onRecentFpsUpdate({
          avgVideoSpeed: this.avgVideoSpeed,
          avgDetectSpeed: this.avgDetectSpeed,
          avgVideoFps: 1000 / this.avgVideoSpeed,
          avgDetectFps: 1000 / this.avgDetectSpeed,
          detectInterval: this.detectFrequency,
        })
      }
    }
  }

  scaleBackResultLocations(locationObj: QRCode['location']) {
    // scaleをいじっている場合はもとに戻す
    const props: (keyof QRCode['location'])[] = [
      'topLeftCorner',
      'topRightCorner',
      'bottomLeftCorner',
      'bottomRightCorner',
      'topLeftFinderPattern',
      'topRightFinderPattern',
      'bottomLeftFinderPattern',
      'bottomRightAlignmentPattern',
    ]
    for (const prop of props) {
      const obj = locationObj[prop]
      if (obj) {
        obj.y /= this.frameResizeFactor
        obj.x /= this.frameResizeFactor
      }
    }
  }

  afterFrameDecoded(result: QRCode | null) {
    if (!result) { return }
    if (this.locate) {
      this.scaleBackResultLocations(result.location)
      // 変な形は排除する
      const tl = result.location.topLeftCorner
      const tr = result.location.topRightCorner
      const bl = result.location.bottomLeftCorner
      const lenTop = Math.hypot(tr.x - tl.x, tr.y - tl.y)
      const lenLeft = Math.hypot(bl.x - tl.x, bl.y - tl.y)
      if (lenTop === 0 || lenLeft === 0) { return }
      const lenRatio = lenTop > lenLeft ? lenLeft / lenTop : lenTop / lenLeft
      // 正方形からすごくはずれてたら無視
      if (lenRatio < 0.5) { return }
      const rad = Math.atan(lenLeft / lenTop)
      // 45度くらいのはずなので大きくはずれた形状は無視
      if (rad < Math.PI / 8 || rad > Math.PI / 8 * 3) { return }

      this.lastDetectLocation = { area: result.location, ts: performance.now() }
      this.drawDetectionLocation()
    }

    this.onDetection_(result)
  }

  checkInvalidStatus() {
    // check stream state and restart if not enabled
    if (!this.mediaStream) {
      this.restart()
      return
    }
    for (const track of this.mediaStream.getTracks()) {
      if (track.kind !== 'video') { continue }
      if (!track.enabled) {
        Bugsnag.notify(new Error('Detected disabled video stream. Restart qrcode_manager.'))
        this.restart()
        return
      }
    }
  }

  init_(): Promise<void> {
    if (this.useWorker) {
      if (this.qrcodeWorker) {
        this.qrcodeWorker.terminate()
      }
      const qrcodeWorker = new QRDecodeWorker()
      qrcodeWorker.addEventListener('message', evt => {
        this.onFrameDecoded(evt)
      })
      qrcodeWorker.addEventListener('error', err => {
        const errEvtWrap = Object.assign({ name: 'workerError' }, err)
        Bugsnag.notify(errEvtWrap)
      })
      this.qrcodeWorker = qrcodeWorker
      this.isQrcodeWorkerReady = true
    }

    return new Promise((resolve, reject) => {
      getUserMedia({ video: this.videoConstraints, audio: false })
        .then(stream => {
          this.mediaStream = stream
          if (!this.video) {
            return reject(new Error('video element not found'))
          }
          this.video.srcObject = stream
          return this.video.play().then(() => {
            this.isTickStopped = false
          })
        }).then(() => {
          const self = this as QrcodeManager
          {
            let next = null;
            (function videoFrame(timestamp) {
              next = next || timestamp
              if (self.isTickStopped) { return }
              if (timestamp >= next) {
                const tmpNow = performance.now()
                self.updateCanvasImage()
                const elapsed = performance.now() - tmpNow
                self.updateRecentFps(elapsed, null)
                const timeAdd = Math.max(0, self.videoFrequency - elapsed)
                // console.log('videoFrame', self.videoFrequency, elapsed, timeAdd)
                next = timestamp + timeAdd

                // 正常時は数ミリ秒で終わるはず.
                if (self.avgVideoSpeed > 50) {
                  if (self.useAutoReload) {
                    self.isTickStopped = true
                    self.restart()
                    return
                  }
                }
              }
              requestAnimFrame(videoFrame)
            })(performance.now())
          }

          {
            let next = null;
            (function detectionFrame(timestamp) {
              next = next || timestamp
              if (self.isTickStopped) { return }
              if (timestamp >= next) {
                const tmpNow = performance.now()
                self.tryDetect()
                const tmpDiff = performance.now() - tmpNow
                const timeAdd = Math.max(0, self.detectFrequency - tmpDiff)
                // console.log('detectionFrame', self.detectFrequency, tmpDiff, timeAdd)
                next = timestamp + timeAdd

                if (self.avgDetectSpeed > 500) {
                  if (self.useAutoReload) {
                    // リロード可の場合はリロード.
                    location.reload()
                    return
                  } else {
                    // リロード不可の場合、これ以上やっても詰まってしまうので
                    // 頻度を下げる
                    if (self.detectFrequency <= self.avgDetectSpeed) {
                      self.detectFrequency = Math.floor(self.avgDetectSpeed * 1.1)
                    }
                  }
                } else {
                  let frequency = Math.max(self.origDetectFrequency, self.avgDetectSpeed)
                  if (self.useMotionDetect && self.mdStatus!.current !== 'active') {
                    frequency = Math.max(self.idleDetectFrequency, self.avgDetectSpeed)
                  }
                  self.detectFrequency = frequency
                }

                // 久しぶりに画面が動いたような場合はリロード
                if (
                  self.useMotionDetect &&
                  self.mdStatus!.current === 'active' &&
                  self.useAutoReload &&
                  self.mdStatus!.lastIdleTimeMillisec > self.idleTooLongMillisec!
                ) {
                  location.reload()
                  return
                }
              }
              requestAnimFrame(detectionFrame)
            })(performance.now())
          }

          {
            let next = null;
            (function checkFrame(timestamp) {
              next = next || timestamp
              if (self.isTickStopped) { return }
              if (timestamp >= next) {
                const tmpNow = performance.now()
                self.checkInvalidStatus()
                const tmpDiff = performance.now() - tmpNow
                const timeAdd = Math.max(0, 3000 - tmpDiff)
                next = timestamp + timeAdd
              }
              requestAnimFrame(checkFrame)
            })(performance.now())
          }

          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  pause() {
    this.isTickStopped = true
  }

  clearMediaStream_() {
    if (!this.mediaStream) { return }
    this.mediaStream.getTracks().forEach(track => {
      track.stop()
    })
  }

  restart() {
    this.pause()
    this.clearMediaStream_()
    this.ready = this.init_()
  }

  destroy() {
    this.pause()
    this.clearMediaStream_()
    // try to delete as much as possible
    if (this.canvas) {
      this.canvasCtx!.clearRect(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      )
      this.canvas.width = 0
      this.canvas.height = 0
    }

    if (this.sizeAdjustedCanvas) {
      this.sizeAdjustedCanvasCtx!.clearRect(
        0,
        0,
        this.sizeAdjustedCanvas.width,
        this.sizeAdjustedCanvas.height
      )
      this.sizeAdjustedCanvas.width = 0
      this.sizeAdjustedCanvas.height = 0
    }

    this.video = null
    this.canvas = null
    this.canvasCtx = null
    this.sizeAdjustedCanvas = null
    this.sizeAdjustedCanvasCtx = null
    this.mdCanvas = null
    this.mdCanvasCtx = null
    this.lastImageData = null
    this.onDetect = (_result: QRCodeResult) => {}
    this.ready = Promise.resolve()
  }

  async getActiveStreamLabel_(): Promise<string> {
    await this.ready
    if (!this.mediaStream) { return '' }
    const tracks = this.mediaStream.getVideoTracks()
    return tracks && tracks.length > 0 ? tracks[0].label : ''
  }

  async enumerateVideoDevices_(): Promise<MediaDeviceInfo[]> {
    const devices = await enumerateDevices()
    return devices.filter(device => device.kind === 'videoinput')
  }

  async getCameraSelections(): Promise<{ activeCameraId: string, selections: VideoDeviceInfo[] }> {
    const activeStreamLabel = await this.getActiveStreamLabel_()
    const devices = await this.enumerateVideoDevices_()

    function pruneText(text: string) {
      text = text || ''
      return text.length > 30 ? text.substr(0, 30) : text
    }

    let activeCameraId = ''
    const selections = devices.map<VideoDeviceInfo>(device => {
      const label = pruneText(device.label || device.deviceId)
      if (activeStreamLabel === device.label) {
        activeCameraId = device.deviceId
      }
      return { id: device.deviceId, label }
    })
    return { activeCameraId, selections }
  }

  updateCameraSize(w: number, h: number): void {
    this.videoConstraints.width = w
    this.videoConstraints.height = h
    this.restart()
  }

  updateCamera(camId: string): void {
    if (camId) {
      this.videoConstraints.deviceId = camId
    } else {
      delete this.videoConstraints.deviceId
    }
    this.restart()
  }

  clearLastDetection(): void {
    this.lastCodeResultTs = new Date(1970, 0, 1)
    this.lastCodeResult = {}
  }

  disableReader(): void {
    this.isReaderDisabled = true
  }
}
