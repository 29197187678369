





























































import { defineComponent, SetupContext, onMounted, reactive, ref } from '@vue/composition-api'
import { ensureSuperAdmin } from 'src/hooks/appRoleHook'
import QrcodeManager, { InitOptions, QRCodeResult, VideoDeviceInfo } from 'src/util/qrcode_manager'

interface DetectionInfo {
  id: string
  code: string
  dataUrl: string
}

interface State {
  isAuthorized: boolean
  isRunning: boolean
  cameraSelections: VideoDeviceInfo[]
  detections: DetectionInfo[]
  options: {
    resolution: string
    patchSize: string
    halfSample: boolean
    numWorkers: number,
    cameraId: string
  }
}

function getQrcodeManagerInitialState(qrcodeManagerDomRoot: Element): InitOptions['state'] {
  return {
    inputStream: {
      constraints: {
        width: { min: 640 },
        height: { min: 480 },
        facingMode: 'environment',
        aspectRatio: { min: 1, max: 2 },
      },
      target: qrcodeManagerDomRoot,
    },
    locate: true
  }
}
function getQrcodeManager(qrcodeManagerDomRoot: Element, detections: DetectionInfo[]): QrcodeManager {
  const onDetect = (result: QRCodeResult): void => {
    const det = {
      id: new Date().valueOf() + '#' + Math.random(),
      code: result.code,
      dataURL: result.opts.dataCanvas.toDataURL(),
    }
    detections.unshift(det)
  }
  return new QrcodeManager({
    state: getQrcodeManagerInitialState(qrcodeManagerDomRoot),
    onDetect,
  })
}

export default defineComponent({
  setup(_props, context: SetupContext) {
    let qm: QrcodeManager | null = null
    const state: State = reactive({
      isAuthorized: false,
      isRunning: true,
      cameraSelections: [],
      detections: [],
      options: {
        resolution: '640x480',
        patchSize: 'medium',
        halfSample: true,
        numWorkers: navigator.hardwareConcurrency || 1,
        cameraId: '',
      },
    })
    const refQrcodeManagerDomRoot = ref<Element>()

    async function startDetection(): void {
      state.detections = []
      state.isRunning = true
      qm = getQrcodeManager(refQrcodeManagerDomRoot.value, state.detections)
      await qm.ready
      const { activeCameraId, selections } = await qm.getCameraSelections()
      state.options.cameraId = activeCameraId
      state.cameraSelections = selections
    }

    function stopDetection(): void {
      qm && qm.destroy()
      state.isRunning = false
    }

    function updateCameraSize(value: string): void {
      const [w, h] = value.split('x')
      qm && qm.updateCameraSize(parseInt(w), parseInt(h))
      console.log('camera size changed to ' + value)
    }

    function updateSelectedCamera(camId: string): void {
      qm && qm.updateCamera(camId)
    }

    onMounted(async() => {
      await ensureSuperAdmin(context)
      state.isAuthorized = true
      await startDetection()
    })

    return {
      state,
      refQrcodeManagerDomRoot,
      startDetection,
      stopDetection,
      updateCameraSize,
      updateSelectedCamera,
    }
  },
})
